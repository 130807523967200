


/* @font-face{
  font-family: "Noto-SansM";
  font-weight: 400;
  src: url("../src/style/fonts/NotoSansKR-Medium.otf") format("opentype");
}  */

@font-face{
  font-family: "PretendardM";
  font-weight: 400;
  src: url("../src/style/fonts/Pretendard-Medium.otf") format("truetype")
} 
@font-face{
  font-family: "PretendardL";
  font-weight: 300;
  src: url("../src/style/fonts/Pretendard-Light.otf") format("truetype")
} 




body {
  background: #ffffff;
}


body {
  overflow-x: hidden;
}

.App {
  object-fit: cover;
  display: block;
  /* margin-top: 58px; */
}

/* original */
/* .mainBar {
  display: flex;
  position: fixed;
} */






/* 230814 new-test */


.mainBar {
  /* display: flex; */
  background-color: #0F2741;  
  width: 100%;
  height: 60px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 17px;
  position: fixed;
  z-index: 99;
  top: 0;
}

.logoMain {
  max-width: 120px;
  height: auto;
  /* height: 22px; */
  display: flex;
}

.menu {
  position: absolute;
  right: 0;
  display: flex;
}

.menu_font { 
  font-family: "PretendardM";
  font-weight: 400;
  margin: 0;
  font-size: 1rem;
  
}

.lang_font {
  font-family: "PretendardM";
  font-weight: 400;
  font-size: 0.7rem;
}

.menu .items {
  font-family: "PretendardM";
  /*  */
  font-weight: 400;
  padding-right:25px;
  text-decoration: none;
  color: white;
  opacity: 0.6;
}

.menu .items:hover {
  opacity: 1;
}

/* .menu .items:active {
  padding-right:25px;
} */

.main-bg-wrap {
  height: 100vh;
  background-image: url('../src/img/bg_test.mp4');
  /* height: 100%; */
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}


.main-bg-wrap .content {
  height: 480px;
  width: 1000px;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.main-bg-wrap .content span {
  color: white;
  align-self: center;
}

/* .content span:nth-child(1)  {
  font-size: 25px;
  font-weight: bold;
} */

.main-bg-wrap .content {
  font-size: 20px;
  font-weight: 500;
  font-family: "PretendardM";
  /*  */
  line-height: 34.75px;
}



/* 메인 랜딩 스타일 설정

.main-bg-wrap.landing,
.main-bg-wrap.meeting {
  height: 480px;
  width: 1000px;
  margin: auto;
  display: flex;
  flex-direction: row;
}

.main-bg-wrap .landing h3, 
.main-bg-wrap .landing p,
.main-bg-wrap .landing span {
  font-family: 'PretendardL';
  font-weight: 300;
  color: white;
}

.main-bg-wrap .landing h1 {
  font-family: 'PretendardM';
  font-weight: 700;
  color: white;
}


.main-bg-wrap .meeting h3,
.main-bg-wrap .meeting p,
.main-bg-wrap .meeting span {
  font-family: 'PretendardL';
  font-weight: 300;
  color: white;
}

.main-bg-wrap .meeting h1 {
  font-family: 'PretendardM';
  font-weight: 600;
  color: white;
}

.meet_field {  
  font-family: "PretendardM";
  width: 589px;
  height:35px;  
  max-width: 589px;
  font-size: 16px !important;
  line-height: 24px;
  margin-bottom:12px;
  margin-left: auto;
  margin-right: auto;
}


.meet_msg {
  font-family: "PretendardM";
  font-size: 16px !important;
  line-height: 25px !important;
  max-width: 589px;
  height: 124px;
  padding-left: 10px;
  padding-top: 3px;
  margin-bottom:12px;
  margin-left: auto;
  margin-right: auto;
}

.main-bg-wrap .meeting [type=submit] {
  font-family: 'PretendardM';
  font-weight: 700;
  color: white;
  border: solid 2px white; 
  background-color:transparent;
}

.form_field:hover {
  border-color: #767676;
} */

/* @media only screen and (max-width: 1280px) {
  .meet_field {
    max-width: 349px;
    font-size: 12px !important;
    margin-bottom:12px;
    padding-left: 10px !important;
    padding-top: 10px !important;
  }

  .main-bg-wrap .landing span {
    line-height: 18px !important;
  }

  .meet_msg {
    max-width: 349px;
    font-size: 12px !important;
    height: 64px;
    padding-left: 10px !important;
    padding-top: 3px !important;
    line-height: 20px !important;
    margin-bottom:12px;
  }
} */

@media only screen and (max-width: 960px) {

  .mainBar {
    padding-left: 15px;
  }
  

  .main-bg-wrap .content {
    font-size: 18px;
  }

  /* .meet_field {
    width: 589px;
    height:33px;  
    font-size: 16px !important;
    max-width: 349px;
    width: 349px;
    height:30px;
    margin-bottom:10px;
    font-size: 12px !important;
    
  } */
  
  /* .meet_msg {
    max-width: 349px;
    width: 349px;
    height:77px;
    margin-bottom:10px;
    font-size: 12px !important;
  } */
}

@media only screen and (max-width: 760px) {
  .main-bg-wrap .content {
    width: auto;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
  }
  
  
}

/* @media only screen and (max-width: 600px) {
  .main-bg-wrap .landing button, 
  .main-bg-wrap .meeting button {
    font-size: 13px;
  }
} */

@media only screen and (max-width: 480px) {
  .main-bg-wrap .content {
    font-size: 15px;
  }

  /* .main-bg-wrap .landing h1 {
    font-size: 45px;
  }

  .main-bg-wrap.landing, 
  .main-bg-wrap.meeting {
    width: 90%;
  }

  .meet_field {
    width:80%;
    height:30px;  
    font-size: 12px !important;
  }
  .meet_msg {
    width:80%;
    height:90px;  
    font-size: 12px !important;
    margin-bottom: 22px;
  } */
}


.sub-bg-wrap-svc {
  background-image: url('../src/img/svc_img.png') !important;
}
.sub-bg-wrap-about {
  background-image: url('../src/img/about_img.png') !important;
}
.sub-bg-wrap-tech {
  background-image: url('../src/img/tech_img.png') !important;
}
.sub-bg-wrap-media {
  background-image: url('../src/img/media_img.png') !important;
}
.sub-bg-wrap-contact {
  background-image: url('../src/img/contact_img.png') !important;
}

.sub-bg-wrap {
  /* background-image: url('../src/img/bg_img_main.png'); */
  height: 250px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "PretendardM";
  /*  */
  font-weight: 700;
}

.sub-bg-wrap .content {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10%;
  margin-right: 10%;
  padding-top: 50px;
}

.sub-bg-wrap .content span {
  color: white;
  align-self: center;
  
}

.sub-bg-wrap .content span:nth-child(1)  {
  font-size: 32px;
  font-weight: 700;
  line-height: 46.34px;
}

@media only screen and (max-width: 960px) {
  .sub-bg-wrap .content span:nth-child(1)  {
    font-size: 18px;
    line-height: 21.6px;
  }
}




/* .dropDown div.hr1 {
  width: 200px;
  height: 0.8px;
} */

.dropDown2 div.hr2 {
  width: 200px;
  height: 0.5px;
}

.dropDown1 div.hr2 {
  width: 200px;
  height: 0.5px;
}

.dropDown {
  position: fixed;
  right: 0;
  /* margin-right: 230px; */
  margin-right: 430px;
  /* margin-top: 0.25rem; */
}

#logoImg {
  max-width: 120px;
  width: 500px;
}

.dropDown1 {
  position: fixed;
  right: 0;
  margin-right: 230px;
  /* margin-right: 30px; */

  /* margin-top: 0.50rem; */
}

.dropDown2 {
  position: fixed;
  right: 0;
  margin-right: 30px;

  
}


/** save nature*/
#primary_nav_wrap ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#primary_nav_wrap ul li.current-menu-item {
  background: none;
}


#primary_nav_wrap ul ul {
  display: none;
  list-style: none;
  position: relative;
  background: none;
  padding: 0;
}

#primary_nav_wrap ul ul li {
  width: 200px;
  background: none;

}

#primary_nav_wrap ul ul ul {
  top: 0;
}

#primary_nav_wrap:hover {
  display: block;
  cursor: pointer;
}

#primary_nav_wrap:active ul li:hover>ul {
  display: block;
}

#primary_nav_wrap ul li:hover>ul {
  display: block;
}

/*deepvisions*/
#primary_nav_wrap1 ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#primary_nav_wrap1 ul li.current-menu-item {
  background: none;
}


#primary_nav_wrap1 ul ul {
  display: none;
  list-style: none;
  position: relative;
  background: none;
  padding: 0;
}

#primary_nav_wrap1 ul ul li {
  width: 200px;
  background: none;

}

#primary_nav_wrap1 ul ul ul {
  top: 0;
}

#primary_nav_wrap1:hover {
  display: block;
  cursor: pointer;
}

#primary_nav_wrap1:active ul li:hover>ul {
  display: block;
}

#primary_nav_wrap1 ul li:hover>ul {
  display: block;
}





.footer {
  width: 100%;
  min-height:60px;
  /* position: fixed; */
  bottom: 0;
  z-index: 99;
  padding: 16px;
  background: #263238;
}

.footer span {

  color: #767676;
  font-family: "PretendardM";
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.d_flex {
  display: flex;
}

/* font-weight */
.fw_800 {
  font-weight: 800;
}
.fw_700 {
  font-weight: 700;
}
.fw_600 {
  font-weight: 600;
}
.fw_400 {
  font-weight: 400;
}
.fw_200 {
  font-weight: 200;
}


/* font-size */
.fs_40 {
  font-size: 40px !important;
}
.fs_38 {
  font-size: 38px !important;
}
.fs_36 {
  font-size: 36px !important;
}
.fs_34 {
  font-size: 34px !important;
}
.fs_32 {
  font-size: 32px !important;
}
.fs_30 {
  font-size: 30px !important;
}
.fs_28 {
  font-size: 28px !important;
}
.fs_26 {
  font-size: 26px !important;
}
.fs_24 {
  font-size: 24px !important;
}
.fs_22 {
  font-size: 22px !important;
}
.fs_20 {
  font-size: 20px !important;
}
.fs_18 {
  font-size: 18px !important;
}
.fs_16 {
  font-size: 16px !important;
}

.ic_award {
  width: 32px;
  height: 32px;
}

.ic_cert {
  width: 40px;
  height: 40px;
}
.ic_lang {
  width: 24px;
  height: 24px;
}

.ic_tech {
  width: 65px;
  height: 56px;
}

.ic_media {
  width: 32px;
  height: 32px;
}

.ic_svc {
  width: 32px;
  height: 32px;
}

.color_default {
  color: #4D4D4D !important;
}

.color_back_sub {
  background-color: #F6F7F9;
}

#menu_lang {
  color:black;
  text-align: center;
  /* opacity: 0.8; */
  height: 45px;
}
#menu_lang > ul {
  padding-left: 24px;
}
#menu_lang > ul > li:hover {
  opacity: 1;
}
#menu_lang > ul > li {
  float:left;
  position:relative;
  padding-right: 40px;
  padding-bottom: 10px;
  opacity: 0.8;
}

ul li{
  list-style: none;
  margin: 0;
  padding: 0;
}

#menu_lang > ul > li > ul {
  font-family: "PretendardM";
  display:none;
  position: absolute;
  font-size:14px;
  background: white;
  border-radius: 8px;
  /* border-style: #767676 solid 5px; */
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 0;
  padding-right: 0;
  margin-top: 10px;
  margin-left: -10px;
  margin-right: 20px;
}

/* 한/영 보일 때 */
#menu_lang > ul > li:hover > ul {
  display:block;
  color: black !important;
  border: 1px solid #767676 !important;
}

#menu_block > a > li {
  background-color: white !important;
  color: #0F2741 !important;
}
#menu_block > a > li:hover {
  background-color: #0F27410F !important;
  color: black !important;
}


/* .menu_block {
  border: 1px solid #767676 !important;
  
} */

[data-aos] {
  pointer-events: none;
}

.aos-animate {
  pointer-events: auto;
}
.aos-init[data-aos][data-aos].aos-animate {
  transform: unset;
}


/* .media */

.media > a {
  cursor: pointer;
  text-decoration:none;
  color:#323232;
  font-size: 20px;
  line-height: 34.75px;
  font-family: "PretendardM";
  /*  */
}

.media > a > span:nth-child(2) {
  
}

.media > a:hover {
  color:#0A15AA;
}

.media_txt {
  font-size: 18px;
}

.media_video {
  max-width: 85%;
  margin: auto;
}


@media only screen and (max-width: 960px) {
  .media_txt {
    font-size: 16px;
  }

  .media_video {
    max-width: 100%;
  }

}







/* about */
.contet_tit {
  font-family: "PretendardM";
  /* src: url("../src/style/fonts/Inter-Medium.woff") format("woff"); */
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: #4D4D4D;
  
}
.contet_sub {
  font-family: "PretendardM";
  /* src: url("../src/style/fonts/Inter-Medium.woff") format("woff"); */
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #717171;
  
}

.about_txt {
  font-family: "PretendardM";
  /*  */
  font-weight: 400;
  font-size: 20px;
  line-height: 28.96px;
  color: black;
  
  margin-top: 20px;
  margin-bottom: 60px;
}

.about_subtit {
  font-family: "PretendardM";
  font-weight: 400;
  font-size: 20px;
  line-height: 20.27px;
  color: black;
  margin-top: 20px;
  margin-bottom: 8px;
}

.about_subtxt {
  font-family: "PretendardM";
  font-weight: 400;
  font-size: 14px;
  line-height: 20.27px;
  color: black;
  
}

/* history */
.history_tit {
  font-family: "PretendardM";
  /* src: url("../src/style/fonts/Inter-Medium.woff") format("woff"); */
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: #4D4D4D;
  
}
.history_sub {
  font-family: "PretendardM";
  /* src: url("../src/style/fonts/Inter-Medium.woff") format("woff"); */
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #717171;
  
}

/* team */
.team_tit {
  font-family: "PretendardM";
  /* src: url("../src/style/fonts/Inter-Medium.woff") format("woff"); */
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: #4D4D4D;
  
}
.team_sub {
  font-family: "PretendardM";
  /* src: url("../src/style/fonts/Inter-Medium.woff") format("woff"); */
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #717171;
  
}

.team_cover{
  margin-top: 40px;
  margin-bottom: 40px;
}

.team_sub_cover{
  width: 100% ;
  display: flex;
}

.team_box {
  display: flex !important;
  width: 50% !important;
}

.team_display {
  font-family: "PretendardM";
  font-weight: 700;
  font-size: 24px;
  line-height: 44px;
  color: #4D4D4D;
  text-align: center;

  width: 10.5rem;
}
.team_name {
  font-size: 16px !important;
  line-height: 23.17px !important;
  font-weight: 400;
}
.team_txt {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.17px;
  text-align: start;
}

/* awards */
.awards_tit {
  font-family: "PretendardM";
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  color: #4D4D4D;
  
}
.awards_sub {
  font-family: "PretendardM";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #717171;
  
}
.awards_mb{
  margin-top: 40px;
}


.awards_txt {
  font-family: "PretendardM";
  /*  */
  font-weight: 400;
  font-size: 20px;
  line-height: 34.75px;
  color: black;
}

.partner_cover {
  padding-top: 62px;
  padding-bottom: 80px;
}

.partner_960 {
  min-width: 101px;
  margin: auto;
  text-align: center;
}

.partner_960_under {
  min-width: 101px;
  margin: auto;
  
}

@media only screen and (max-width: 960px) {

  .contet_tit {
    font-size: 20px;
    line-height: 44px;
  }

  .contet_sub {
    font-size: 12px;
    line-height: 24px;
  }

  .about_txt {
    font-size: 16px;
    margin-top: 55px;
    margin-bottom: 40px;
  }

  .about_subtit { 
    font-size: 16px;
    line-height: 22px;
    
    margin-bottom: 15px;
  }
  .about_subtxt { 
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 90px;
  }

  .history_tit {
    font-size: 20px;
    line-height: 44px;
  }

  .history_sub {
    font-size: 12px;
    line-height: 24px;
    /* margin-bottom: 91px; */
  }

  .team_tit {
    font-size: 20px;
    line-height: 44px;
  }

  .team_sub {
    font-size: 12px;
    line-height: 24px;
  }

  .team_cover{
    margin-top: 70px;
  }

  .team_box {
    display: block !important;
    width: 50% !important;
    
  }

  .team_display {
    text-align: left;
    margin-left: 16px;
    font-size: 22px;
    line-height: 37.86px;
    width: auto;
  }

  .team_name {
    font-size: 14px !important;
    line-height: 20.27px !important;
    font-weight: 700;
  }

  .team_txt {
    font-size: 11px;
    line-height: 18px;
  }

  .awards_tit {
    font-size: 20px;
    line-height: 44px;
  }

  .awards_sub {
    font-size: 12px;
    line-height: 24px;
    /* margin-bottom: 70px; */
  }

  .awards_mb{
    margin-top: 70px;
  }

  .awards_txt {
    font-size: 18px;
  }

  .partner_cover {
    padding-top: 76px;
    padding-bottom: 85px;
  }


}


@media only screen and (max-width: 720px) {

  .team_sub_cover{
    width: 100% ;
    
    display: block;
  }

  .team_box {
    width: 60% !important;
    margin: auto;
  }

  .awards_none {
    display: none;
  }

}


@media only screen and (max-width: 600px) {

  .team_box {
    width: 80% !important;
  }

  
}





/* tech */
.tech_back {
  height: 30px;
  background-color: #E6F2FF;
}

.tech_tit {
  font-family: "PretendardM";
  /* src: url("../src/style/fonts/Inter-Medium.woff") format("woff"); */
  font-weight: 600;
  font-size: 33px;
  line-height: 44px;
  color: #4D4D4D;
  text-align: left;
  
}
.tech_sub {
  font-family: "PretendardM";
  /*  */
  font-weight: 400;
  font-size: 17px;
  line-height: 28.96px;
  color: black; 
  text-align: start;
}

.tech_app_tit {
  font-family: "PretendardM";
  font-weight: 600;
  font-size: 33px;
  line-height: 44px;
  color: #4D4D4D;
}

.tech_app_sub {
  font-family: "PretendardM";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #18191F;
}


.tech_mini {
  font-family: "PretendardM";
  /* src: url("../src/style/fonts/Inter-Medium.woff") format("woff"); */
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #18191F;
  
}

.tech_txt {
  font-family: "PretendardM";
  font-weight: 400;
  font-size: 28px;
  line-height: 34.75px;
  color: black;
  
}
.tech_txt_mini {
  font-family: "PretendardM";
  font-weight: 400;
  font-size: 16px;
  line-height: 23.17px;
  color: #717171;
  text-align: start;
}


.tech_cert_tit {
  font-family: "PretendardM";
  font-weight: 600;
  font-size: 33px;
  line-height: 44px;
  color: #4D4D4D;
  text-align: start;
}

.tech_cert_sub {
  font-family: "PretendardM";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #18191F;
  text-align: start;
}

.cert_txt {
  font-family: "PretendardM";
  /* src: url("../src/style/fonts/Inter-Medium.woff") format("woff"); */
  font-weight: 700;
  font-size: 18px;
  line-height: 38px;
  color: #4D4D4D;
}



@media only screen and (max-width: 960px) {
  .tech_tit {
    font-size: 30px;
  }

  .tech_sub {
    font-size: 14px;
    line-height: 20px;
  }

  .tech_app_tit {
    font-size: 24px;
    
  }
  .tech_app_sub {
    font-size: 12px;
  }

  .tech_txt {
    font-size: 20px;
  }
  .tech_txt_mini {
    font-size: 11px;
    
  }


  .tech_cert_tit {
    font-size: 24px;
    text-align: center;
  }
  .tech_cert_sub {
    font-size: 12px;
    text-align: center;
  }

}



/* contact */

.contact {

}

.contact_tit {
  font-size: 21px;
}

.w_form {
  width: 70% !important;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  margin-right: auto;
  margin-left: auto;
}


.form_field {
  font-family: "PretendardM";
  font-weight: 400 !important;
  font-size: 20px !important;
  color: black;
  line-height: 28.96px;
}

.form_field:hover {
  border-color: #767676;
}
/* .form_field:hover {
 
} */

.form_submit {
  font-family: "PretendardM";
  /* src: url("../src/style/fonts/NotoSansKR-Medium.otf") format("otf") !important; */
  font-weight: 400 !important;
  font-size: 16px !important;
  border-radius: 8px !important;
  background-color: #161C44 !important;
  color: white !important;
}
.form_submit:hover {
  font-weight: 600 !important;
}

.contact_txt {
  font-family: "PretendardM";
  /*  */
  font-weight: 400;
  font-size: 18px ;
  color: black;
  line-height: 28.96px;
  
}
.h_200 {
  height: 200px;
}

.map_tit {
  font-family: "PretendardM";
  /*  */
  font-weight: 700;
  font-size: 24px;
  color: black;
  line-height: 44px;
}

.map_content {
  
  margin-bottom: 2.5rem;
  
  margin-left: 1.25rem;
  margin-right: auto;
}

@media only screen and (max-width: 960px) {
  .contact_tit {
    font-size: 17px;
  }
  .w_form {
    width: 100% !important;
    
  }

  .contact_txt {
    font-size: 16px;
  }

  .map_tit {
    font-size: 24px;
  }

}

@media only screen and (max-width: 600px) {
  .map_content {
  
    margin-left: auto;
  }
}


.txt_space {

}


.carousel-indicators > button {
  border-radius: 50%;
  /* border: 1px solid white !important; */
  width: 12px !important;
  height: 12px !important;
  margin: 5px !important;
}

/* 캐러셀 이전 버튼 */
.carousel-control-prev {
  position: fixed !important;
}

.carousel-control-prev:hover {
  opacity: 1 !important;
  }

/* 캐러셀 다음 버튼 */
.carousel-control-next {  
  position: fixed !important;
}
.carousel-control-next:hover {  
  opacity: 1 !important;
}

/* .service */

.svc > a {
  cursor: pointer;
  text-decoration:none;
  color:#323232;
  font-size: 20px;
  line-height: 34.75px;
  font-family: "PretendardM";
  /*  */
}

.svc > a > span:nth-child(2) {
  
}

.svc > a:hover {
  color:#0A15AA;
}

.svc_txt {
  font-size: 18px;
}

.svc_block {
  max-width: 85%;
  margin: auto;
}

.svc_img {
  border: solid black 1px;
}


@media only screen and (max-width: 960px) {
  .svc_txt {
    font-size: 16px;
  }

  .svc_video {
    max-width: 100%;
  }

}






.main_txt {
  font-size: 11px;
}



/* 부트스트랩 캐러셀 */
.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.6;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 1;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators > button:hover {
  opacity: 1;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.6;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, 
[data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], 
[data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}
[data-bs-theme=dark] .carousel .carousel-caption, 
[data-bs-theme=dark].carousel .carousel-caption {
  color: #000;
}



video { 
  height: 100vh;
  position: absolute; 
}

.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 1;
}

.bg-video__content {
  height: 100%;
  width: 100%;
  object-fit: cover;  /* background-size: cover 와 비슷함. (HTML 요소 or 비디오와 작동) */
  
}



@media only screen and (max-width: 600px) {

    .carousel-control-next {
      display: none;
    }
  
    .carousel-control-prev {
      display: none;
    }

}