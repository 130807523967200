
input[id="menuicon"] {
	display: none;
} 

label{
	margin-left: 15px;
}

input[id="menuicon"] + label {
	display: block;
	width: 35px;
	height: 25px;
	right: 0;
	position: fixed;
	cursor: pointer;
	transition: all 0.47s;
	margin-right: 15px;
}

/* input[id="menuicon"] + label span{
	display: block;
	position: absolute;
	width: 37.5px;
	height: 3px;
	background: #fff;
	transition: all .35s;
	right: 0;
} */

/* @media only screen and (max-width: 960px) {
    input[id="menuicon"] + label span{
		background: black;
	}
} */

input[id="menuicon"] +  label span:nth-child(1){
	width: 25px;
	top: 0;
}

input[id="menuicon"] +  label span:nth-child(2){
	top: 50%;
    width: 25px;
	transform: translateY(-50%);
}

input[id="menuicon"] +  label span:nth-child(3){
    width: 25px;
	bottom: 0;
} 

/* x 자로 바꾸기*/
input[id="menuicon"]:checked +  label span:nth-child(1){
	top: 50%;
    width: 25px;
	transform: translateY(-50%) rotate(45deg);
}

input[id="menuicon"]:checked +  label span:nth-child(2){
	opacity: 0;
}

input[id="menuicon"]:checked +  label span:nth-child(3){
	bottom: 50%;
	transform: translateY(50%) rotate(-45deg);
} 


.vertical{
	min-width: 17rem;
    width: 17rem;
    height: 300vh;
    display:flex;
    position: fixed;
    background-color: #323232;
	opacity: 0.85;
    top: 0;
    right: 0;
    /* box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1); */
    -webkit-transition: all 0.4s ease-out;
	-moz-transition: all 0.4s ease-out;
	-ms-transition: all 0.4s ease-out;
	-o-transition: all 0.4s ease-out;
	transition: all 0.4s ease-out;
    margin-right: -17rem;
	/* z-index: 99; */
}

/* 메뉴바 클릭시 사이드바 나오거나 사라지게*/
input[id="menuicon"]:checked + label{
	margin-right: 28.86px;
	z-index: 1000;

}

input[id="menuicon"]:checked + label + div.vertical{
    margin-right: 0.001rem;
	z-index: 99;

}
/* 
div.vertical.active {
    margin-left: -17rem;
} */

@media only screen and (max-width: 600px) {
	input[id="menuicon"] + label {
		margin-right: 10px;
	}
}